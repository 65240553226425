export const resumeDetails = {
  "main": {
    "name": "Katie Harshman",
    "description": "Versatile professional with experience in customer engagement, project support, and process improvement eager to grow in a dynamic environment.",
    "image": "public/images/KatieProfile.jpg",
    "bio1": "I’m excited to work for a fast-growing company because I’m motivated by the opportunity to contribute to meaningful projects, collaborate with diverse teams, and continuously learn in an evolving environment.",
    "bio2": "Motivated and adaptable professional with a background in software development, technical instruction, and customer engagement. Experienced in working with diverse teams, learning new technologies, and supporting business operations through problem-solving and collaboration. Skilled in programming, technical documentation, and CRM tools, with a strong interest in continuous learning and professional growth. Passionate about applying technical knowledge to real-world challenges and contributing to team success.",
    "bio3": "Seeking an opportunity to apply my technical and communication skills in a dynamic environment where I can contribute to projects, support team goals, and continue learning. Enthusiastic about leveraging my problem-solving abilities, technical knowledge, and customer engagement experience to help drive innovation and efficiency. Looking to join a company that values collaboration, professional development, and creative problem-solving, where I can grow while making a meaningful impact.",
    "email": "youremailhere@gmail.com",
    "phone": "555-555-5555",
    "github": "https://github.com/pie1011",
    "projects": "#Projects",
    "resume": "#Resume",
    "address": {
      "street": "(Your Street)",
      "city": "Campbell",
      "state": "California",
      "zip": "(Your Zip/Postal Code)"
    },
    "website": "http://www.katieharshman.com",
    "resumedownload": "http://katieharshman.com",
    "social": [
      {
        "name": "twitter",
        "url": "https://twitter.com/makncheezburger",
        "className": "fa fa-twitter"
      },
      {
        "name": "linkedin",
        "url": "https://www.linkedin.com/in/tim-baker-8420009a/",
        "className": "fa fa-linkedin"
      },
      {
        "name": "instagram",
        "url": "http://instagram.com/",
        "className": "fa fa-instagram"
      },
      {
        "name": "github",
        "url": "https://github.com/pie1011",
        "className": "fa fa-github"
      }
    ],
    "skills": [

      {
        "skill": "Python, Flask, Django"
      },
      {
        "skill": "JavaScript, React"
      },
      {
        "skill": "SQL, Postgres, MongoDB"
      },
      {
        "skill": "Visual Studio Code"
      },
      {
        "skill": "Git & GitHub"
      },
      {
        "skill": "WordPress"
      },
      {
        "skill": "Microsoft Office"
      },
      {
        "skill": "Adobe Suite"
      },
      {
        "skill": "HTML, HTML5"
      },
      {
        "skill": "CSS, SCSS, SASS"
      },
      {
        "skill": "Responsive Design"
      },
      {
        "skill": "Accessibility"
      }
    ]
  },
  "resume": {
    "skillmessage": "Write-up of skills",

    "training": [
      {
        "site": "calbright college",
        "trainingImage": "https://www.calbright.org/programs/crm/",
        "data": [
          {
            "name": "Data Analysis",
            "certificate": "Certificate In Progress",
            "date": "In Progress",
            "description": "• Understanding of data types and sources, organizing complex data using spreadsheets and Structured Query Language (SQL) databases. Durable skills like critical thinking, communication, collaboration, and creativity, as well as character and leadership skills with a growth mindset. Using visualization and storytelling to present data in meaningful ways. Creating a resume and portfolio, powerful job search tools that showcase innovation, organization and effective use of technology."
            
          },
          {
            "name": "Project Management",
            "certificate": "Certificate",
            "date": "January 2025",
            "description": "• Upon completion of the project management program, students will possess a solid foundation in project management principles, methodologies, and documentation. They will be equipped with the necessary skills to excel in project management-based careers across diverse industries, making valuable contributions to organizations through effective project planning, execution, and control. "
          },
          {
            "name": "CRM Platform Administration",
            "certificate": "Certificate",
            "date": "January 2024",
            "description": "• Graduates from the Transition to Technology: Customer Relationship Management Platform Administration program have mastered how to use, configure, monitor, customize, and secure a CRM platform. Graduates have an in-depth understanding of the components and processes involved in customizing and administering a CRM. In addition, graduates utilize best practices for working in a distributed team while demonstrating effective communication skills for workplace interaction."
          }
        ]
      },
      {
        "site": "West Valley College",
        "data": [
          {
            "name": "Associate's Degree",
            "certificate": "AA in Liberal Studies, Social & Behavioral Sciences Emphasis",
            "date": "November 2011"
          }
        ]
      }

    ],
    "trainMore": [
      {
        "site": "udemy.com",
        "data": [
          {
            "name": "Clean Code",
            "certificate": "Certificate",
            "date": "October 2022"
          },
          {
            "name": "Software Testing Processes & Techniques",
            "certificate": "Certificate",
            "date": "October 2022"
          },
          {
            "name": "Java Collections from Basic to Advanced",
            "certificate": "Certificate",
            "date": "April 2023"
          }
        ]
      },
      {
        "site": "codecademy.com",
        "data": [
          {
            "name": "Build a Website HTML, CSS & GitHub Pages",
            "certificate": "Skill Path Certificate",
            "date": "January 2022"
          },
          {
            "name": "Build Python Web Apps with Flask",
            "certificate": "Skill Path Certificate",
            "date": "June 2021"
          }
        ]
      },
      {
        "site": "freecodecamp.org",
        "data": [
          {
            "name": "Responsive Web Design Developer",
            "certificate": "Certificate",
            "date": "November 2019"
          },
          {
            "name": "JavaScript Algorithms & Data Structures",
            "certificate": "Certificate",
            "date": "October 2019"
          }
        ]
      },
      {
        "site": "edx.org",
        "data": [
          {
            "name": "Harvard's CS50x Introduction to Computer Science",
            "certificate": "In Progress",
            "date": "In Progress"
          }
        ]
      }
    ],



    "work": [
      {
        "company": "Firework",
        "title": "Virtual Sales Associate",
        "years": "October 2024 - Present",
        "description": [
          "Market and sell tech products directly to individual customers through personalized 1-to-1 solutions.",
          "Provide personalized and helpful sales experiences by promptly answering all calls.",
          "Demonstrate expertise in Firework’s brand, products, and key selling points to enhance the quality of customer sales interactions.",
          "Master the operation of Firework's technology for 1-to-1 video calls, ensuring a seamless customer experience.",
          "Participate in continuous product knowledge training and educational initiatives to stay informed on updates and advancements."
        ]
      },
      {
        "company": "ThriveDX",
        "title": "Software Development Instructor",
        "years": "April 2022 - November 2024",
        "description": [
          "Teach and mentor cohorts of students (SDSU, UNLV, UCLB, etc.) on programming languages, web development, database management, and software engineering principles.",
          "Participate in instructor training, professional development, and curriculum review to stay current with industry trends and best practices."
        ]
      },
      {
        "company": "IBM",
        "title": "Software Engineer Apprentice ",
        "years": "April 2022 - April 2023",
        "description": [
          "Highlight: Mentored teams in Developer Jumpstart Program-Team BEV won Judges’ Choice Award for Advanced Drone 2.0 Workshop.",
          "Collaborate with senior engineers to develop and maintain software tools for IBM Content Designers, utilizing technologies including Python and JavaScript.",
          "Participate in the development life cycle: design, coding, testing, deployment.",
          "Engage in team-building activities and company-wide events, mentor otherdevelopers.",
          "Attend training sessions and complete coursework to develop technical skills and knowledge of IBM products and services."
        ]
      }
    ],
    "workMore": [
      {
        "company": "Pro Appliance Installation",
        "title": "Co-founder, Roles in Operations Management, Development ",
        "years": "April 2016 - April 2022",
        "description": [
          "Plan and implement business strategies, plans and procedures (startup).",
          "Oversee daily operations (IT, marketing, sales, service order dispatch, etc.).",
          "Manage phones, schedule projects, interact with clients and vendors.",
          "Design and maintain company website Fun Fact: Streamlined client intake!",
          "Administrative support with forms, documents, template."
        ]
      },
      {
        "company": "Los Gatos Swim & Racquet Club",
        "title": "Roles including Administrative Assistant, Childcare Director ",
        "years": "On/Off from 1999 - 2013",
        "description": [
          "Managed member interactions, organized programs, hired and supervised staff.",
          "Administered office tasks including document management, scheduling, and inventory control, contributing to smooth daily operations."
        ]
      }
    ],
    "education": [
      {
        "school": "University?",
        "degree": "Masters in Beer tasting",
        "graduated": "April 2017",
        "description": "Nothing to say here."
      },
      {
        "school": "School #1 Maybe College?",
        "degree": "What did you study 101",
        "graduated": "March 2013",
        "description": "Nothing to say here."
      }
    ],

    "skillsOLD": [
      {
        "name": "Python",
        "level": "60%"
      },
      {
        "name": "VueJs",
        "level": "55%"
      },
      {
        "name": "ReactJs",
        "level": "50%"
      },
      {
        "name": "CSS",
        "level": "90%"
      },
      {
        "name": "PHP",
        "level": "80%"
      },
      {
        "name": "Swift",
        "level": "50%"
      },
      {
        "name": "JAVA",
        "level": "80%"
      }
    ]
  },
  "portfolio": {
    "projects": [
      {
        "title": "Pro Appliance Installation",
        "description": "WordPress website for company at proapplianceinstallation.com",
        "image": "0",
        "url": "https://www.proapplianceinstallation.com",
        "github": ""
      },
      {
        "title": "Simple Chat",
        "description": "Simple multi-room chat in JavaScript with a server using socket.io.",
        "image": "1",
        "url": "",
        "github": "https://github.com/pie1011/chat"
      },
      {
        "title": "The Salon",
        "description": "Python/Flask web app for a mock salon with a database using SQLAlchemy.",
        "image": "2",
        "url": "",
        "github": "https://github.com/pie1011/the-salon"
      }
    ]
  }
}


// {
//   "name": "User Access Fundamentals",
//   "certificate": "Superbadge Unit",
//   "date": "December 2023"
// },
// {
//   "name": "Extended User Access & Restriction",
//   "certificate": "Superbadge Unit",
//   "date": "December 2023"
// },
// {
//   "name": "User Access Troubleshooting",
//   "certificate": "Superbadge Unit",
//   "date": "December 2023"
// },
// 
// Chat live link that doesn't always work 
// https://chatrooms.onrender.com

